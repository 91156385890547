@font-face {
  font-family: 'Trajan Pro Regular';
  font-style: normal;
  font-weight: normal;
  src: local("Trajan Pro Regular"), 
  url("./fonts/trajanpro/TrajanPro-Regular.otf") format("OpenType");
}
@font-face {
  font-family: 'Trajan Pro Bold';
  font-style: normal;
  font-weight: normal;
  src: local("Trajan Pro Bold"), 
  url("./fonts/trajanpro/TrajanPro-Bold.otf") format("OpenType");
}

body {
  margin: 0;
  font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  cursor: pointer !important;
}

#home-cards .MuiTypography-root {
  font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif !important; 
}

#boxSelect .MuiSelect-icon  {
  top:13px !important;
}
#contact-body-input {
  margin-bottom: 4px;
}

a.bbcode_url {
  color: rgb(67, 104, 133);
}

span.MuiTypography-root.MuiListItemText-primary {
  font-size: 20px;
  color: #000;
  font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif;
}

span.react-read-more-read-less.react-read-more-read-less-more {
  font-weight: 600;
  font-size: 15px;
  display: block;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
.css-hyum1k-MuiToolbar-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

@media (min-width:600px) {

  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular {
    min-height: 42px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .css-5nn8jd-MuiContainer-root,
  .css-y5u5ki-MuiToolbar-root,
  .css-s1ni8w-MuiToolbar-root,
  .css-hyum1k-MuiToolbar-root,
  .css-1fpo0vu-MuiToolbar-root, 
  .css-1oqqzyl-MuiContainer-root, 
  .css-owx8u5-MuiContainer-root,
  .css-hyum1k-MuiToolbar-root{
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .css-y5u5ki-MuiToolbar-root,
  .css-1fpo0vu-MuiToolbar-root,
  .css-13zo9jd-MuiToolbar-root,
  .css-s1ni8w-MuiToolbar-root {
    min-height: 42px !important;
  }

  
}

.css-1bjupil-MuiList-root {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

@media (min-width: 1200px) {
  .MuiContainer-root.MuiContainer-maxWidthLg {
    max-width: 1274px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .css-5nn8jd-MuiContainer-root,
  .css-owx8u5-MuiContainer-root {
      max-width: 1274px !important;
  }
}


